/*
-------------------------------------------------------------------------------------------------------
=== Certificate Order Process (COP) ===
-------------------------------------------------------------------------------------------------------
*/

body[scheme='light'] {
    --lce-cop-title-color: var(--xs-color-secondary);
    --lce-cop-subtitle-color: var(--xs-color-secondary);
    --lce-cop-copies-number-color: var(--xs-color-secondary);
}

body[scheme='dark'] {
    --lce-cop-title-color: var(--xs-color-secondary);
    --lce-cop-subtitle-color: var(--xs-color-secondary);
    --lce-cop-copies-number-color: var(--xs-color-secondary);
}

/*
-------------------------------------------------------------------------------------------------------
=== *** ===
-------------------------------------------------------------------------------------------------------
*/

.lce-header-left-stripe {
    border-left: 5px solid var(--xs-color-secondary);
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.lce-cop-title-color {
    color: var(--lce-cop-title-color);
}

.lce-cop-title-color-imp {
    color: var(--lce-cop-title-color) !important;
}

.lce-cop-sub-title-color {
    color: var(--lce-cop-subtitle-color);
}

.lce-cop-sub-title-color-imp {
    color: var(--lce-cop-subtitle-color) !important;
}

.lce-cop-copies-number-title-color {
    color: var(--lce-cop-copies-number-color);
}

.lce-cop-copies-number-title-color-imp {
    color: var(--lce-cop-copies-number-color) !important;
}