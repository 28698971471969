// === Dialog ===
.p-dialog.p-dynamic-dialog {
	.p-dialog-content {
		display: flex;
		flex-direction: column;

		xs-dialog {
			display: flex;
			flex-direction: column;
			flex: 1;

			.xs-dialog {
				flex: 1;

				.xs-dialog-body {
					flex-direction: column;
					flex: 1;

					.xs-dialog-content {
						// TODO:  We need to fix the dialog height to fill the available space. Weird behaviour :-(!
						//flex-direction: column;
						//flex: 1;
						flex: 1 1 100%;

						& > xs-nested-container {
							flex: 1;
							height: 100%;
							flex-direction: column;
						}
					}
				}
			}
		}
	}
}

// ---------------------------------------------------------------------------------------
// --- Field Data ---
// ---------------------------------------------------------------------------------------

.xs-field {
	&.xs-data-field {
		.xs-data-container {
			display: flex;
			direction: row;
			font-weight: 500;
		}

		.xs-data-below-text {
			margin-top: 10px;
			font-size: var(--xs-font-size-small);
		}

		& > label {
			display: inline-block;
			margin-bottom: 4px;
			color: var(--xs-text-secondary-color);
			font-size: 90%;
		}
	}
}
