$cardMaxWidth: 700px;
$cardMinWidth: 400px;
$cardHeight: 250px;
$pageMaxWidth: 1500px;

.lce-smartcity-article-main-category-chip {
    background-color: transparent;
    border: 1px solid var(--xs-color-secondary);
    color: var(--xs-color-secondary);
    font-weight: 600;
}

.lce-smartcity-create-container {
    &.lce-smartcity-main-container {
        max-width: 100%;
    }

    .lce-smartcity-create {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-grow: 1;
        overflow-x: auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;

        .lce-smartcity-create-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-grow: 1;
            min-width: 1000px;

            .lce-smartcity-create-left {
                display: flex;
                flex-direction: column;
                width: 100%;
                flex-grow: 1;
                padding: 15px;
            }

            .lce-smartcity-create-right {
                display: flex;
                flex-direction: column;
                width: 100%;
                flex-grow: 1;
                padding: 0 15px;
            }
        }
    }
}

.lce-smartcity-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $pageMaxWidth;
    flex-grow: 1;
}

.lce-smartcity-article-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: $cardMaxWidth;
    height: $cardHeight;
    overflow: hidden;
    padding: 15px;
    border: 1px solid var(--xs-color-discrete);
    border-radius: var(--xs-border-radius-default);
    background-color: var(--xs-color-discrete);

    .lce-smartcity-article-card-image-container {
        width: 40%;

        .lce-smartcity-article-card-image {
            border-radius: var(--xs-border-radius-default);
            height: 100%;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            object-fit: cover;
        }
    }

    .lce-smartcity-article-card-center {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 60%;

        .lce-smartcity-article-card-title {
            display: block;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 500;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .lce-smartcity-article-card-created-on {
            font-size: var(--xs-font-size-small);
            margin-top: 5px;
            display: block;
        }

        .lce-smartcity-article-card-description {
            font-size: 11px;
            margin-top: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.5;
            margin-bottom: 15px;
        }
    }
}
