$cardMaxWidth: 350px;
$cardHeight: 450px;
$pageMaxWidth: 1500px;

.lce-news-create-container {
    &.lce-news-main-container {
        max-width: 100%;
    }

    .lce-news-create {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-grow: 1;
        overflow-x: auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;

        .lce-news-create-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-grow: 1;
            min-width: 1000px;

            .lce-news-create-left {
                display: flex;
                flex-direction: column;
                width: 100%;
                flex-grow: 1;
                padding: 15px;
            }
            .lce-news-create-right {
                display: flex;
                flex-direction: column;
                width: 100%;
                flex-grow: 1;
                padding: 0 15px;
            }
        }
    }
}

.lce-news-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $pageMaxWidth;
    flex-grow: 1;
}

.lce-news-article-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $cardMaxWidth;
    height: $cardHeight;
    overflow: hidden;
    border: 1px solid var(--xs-color-discrete);
    background-color: var(--xs-color-discrete);
    border-radius: var(--xs-border-radius-default);

    .lce-news-article-card-image-container {
        //border: 2px solid red;
        position: relative;
        width: 100%;
        height: 50%;
        min-height: 50%;
        border-top-left-radius: var(--xs-border-radius-default);
        border-top-right-radius: var(--xs-border-radius-default);

        // Reference: https://www.w3schools.com/css/css3_object-fit.asp
        .lce-news-article-card-image {
            border-top-left-radius: var(--xs-border-radius-default);
            border-top-right-radius: var(--xs-border-radius-default);
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            object-fit: cover;
        }
    }

    .lce-news-article-card-center {
        //border: 1px solid green;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 15px;
        margin-top: 15px;
        flex: 1;
        overflow: hidden;

        .lce-news-article-card-title {
            display: block;
            font-size: var(--xs-font-size-medium);
            font-weight: 500;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .lce-news-article-card-created-on {
            font-size: var(--xs-font-size-small);
            margin-top: 5px;
            display: block;
        }

        .lce-news-article-card-description {
            font-size: var(--xs-font-size-intermediate);
            margin-top: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 1.5;
        }
    }

    .lce-news-article-card-bottom {
        //border: 1px solid blue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-top: 10px;
        width: 100%;

        .lce-news-article-card-bottom-item {
            display: inline-flex;
            align-items: center;

            i {
                display: block;
                margin-right: 5px;
            }
            span {
                display: block;
                font-size: var(--xs-font-size-small);
                font-weight: 400;
            }
        }
    }
}

lce-news,
lce-news-article-create-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
