body {
    overflow: auto;
}

.lce-template-default {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--xs-color-tertiary);

    .lce-template-default-header {
        padding: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .lce-logo {
            max-width: 300px;
        }
    }

    .lce-template-default-content {
        flex-grow: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .lce-template-default-content-absolute {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 15px;

            .lce-template-default-router-outlet {
                border-radius: var(--xs-border-radius-default);
                background: var(--xs-background-color);
                width: 100%;
                max-width: 650px;
            }
        }
    }

    .lce-template-default-footer {
        padding: 15px 15px 8px;
    }
}
