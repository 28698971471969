$xsBreakPointSM: 576px; // 576px 350px
$xsBreakPointMD: 768px;
$xsBreakPointLG: 1300px; // 1300px; // 992px
$xsBreakPointXL: 1600px; // 1600px; // 1200px

:root {
    --xs-breakpoint-sm: #{$xsBreakPointSM};
    --xs-breakpoint-md: #{$xsBreakPointMD};
    --xs-breakpoint-lg: #{$xsBreakPointLG};
    --xs-breakpoint-xl: #{$xsBreakPointXL};
}
