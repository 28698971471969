// ----------------------------------------------------------------------------------------------------
// === Showcase ===
// ----------------------------------------------------------------------------------------------------

.lce-showcase-listing {
  padding: 50px !important;

  .lce-showcase-listing-item {
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 30px;
    }
  }
}

// ----------------------------------------------------------------------------------------------------
// === Background Color ===
// ----------------------------------------------------------------------------------------------------

.lce-background-items-selected {
  background-color: #e3f2fd;
}

.lce-background-color-discrete {
  background-color: var(--lce-background-color-discrete);
}

.lce-app-primary-background-color {
  background-color: var(--lce-app-primary-color);
}

.lce-app-primary-background-color-imp {
  background-color: var(--lce-app-primary-color) !important;
}

.lce-app-secondary-color {
  color: var(--lce-app-secondary-color);
}

.lce-app-secondary-color-imp {
  color: var(--lce-app-secondary-color) !important;
}

.lce-app-secondary-background-color {
  background-color: var(--lce-app-secondary-color);
}

.lce-app-secondary-background-color-imp {
  background-color: var(--lce-app-secondary-color) !important;
}

// ----------------------------------------------------------------------------------------------------
// === Color ===
// ----------------------------------------------------------------------------------------------------

.lce-app-primary-color {
  color: var(--lce-app-primary-color);
}

.lce-app-primary-color-imp {
  color: var(--lce-app-primary-color) !important;
}

.lce-app-primary-color-light {
  color: var(--lce-app-primary-color-light);
}

.lce-app-primary-color-light-imp {
  color: var(--lce-app-primary-color-light) !important;
}

// ----------------------------------------------------------------------------------------------------
// === Border ===
// ----------------------------------------------------------------------------------------------------

.lce-border-app-primary-color {
  border-color: var(--lce-app-primary-color);
}

.lce-border-app-primary-color-imp {
  border-color: var(--lce-app-primary-color) !important;
}

.lce-border-app-primary-color-light {
  border-color: var(--lce-app-primary-color-light);
}

.lce-border-app-primary-color-light-imp {
  border-color: var(--lce-app-primary-color-light) !important;
}

// ----------------------------------------------------------------------------------------------------
// === Scheme ===
// ----------------------------------------------------------------------------------------------------

body[scheme='light'] {
  --lce-app-primary-color: rgb(225, 123, 49);
  --lce-app-primary-color-light: rgb(225, 123, 49, 0.5);
  --lce-app-secondary-color: rgb(234, 166, 56);

  --lce-background-color-discrete: #fafafa;

  --lce-cop-title-color: var(--xs-color-secondary);
  --lce-cop-subtitle-color: var(--xs-color-secondary);
  --lce-cop-copies-number-color: var(--xs-color-secondary);
}

body[scheme='dark'] {
  --lce-app-primary-color: rgb(225, 123, 49);
  --lce-app-primary-color-light: rgb(225, 123, 49, 0.5);
  --lce-app-secondary-color: rgb(234, 166, 56);

  --lce-background-color-discrete: black;

  --lce-cop-title-color: var(--xs-color-danger);
  --lce-cop-subtitle-color: var(--xs-color-danger);
  --lce-cop-copies-number-color: var(--xs-color-danger);
}