@import './lce-shared-common';
@import './lce-certificate-order-process';

$pageMaxWidth: 1500px;

/*
-------------------------------------------------------------------------------------------------------
=== XS Theme ===
-------------------------------------------------------------------------------------------------------
*/

/*
-------------------------------------------------------------------------------------------------------
=== XS ===
-------------------------------------------------------------------------------------------------------
*/

.xs-z-index-minus-10 {
    z-index: -10;
}

.xs-z-index-minus-10-imp {
    z-index: -10 !important;
}

.xs-text-block-small-light-500 {
    display: block;
    font-size: var(--xs-font-size-small);
    color: var(--xs-color-light);
    font-weight: 500;
}

// -------------------------------------------------------------------------------------------------------

.xs-text-intermediate-secondary {
    font-size: var(--xs-font-size-intermediate);
    color: var(--xs-color-secondary);
}

.xs-text-intermediate-secondary-imp {
    font-size: var(--xs-font-size-intermediate) !important;
    color: var(--xs-color-secondary) !important;
}

.xs-text-block-intermediate-500 {
    display: block;
    font-size: var(--xs-font-size-intermediate);
    font-weight: 500;
}

.xs-text-block-intermediate-secondary {
    display: block;
    font-size: var(--xs-font-size-intermediate);
    color: var(--xs-color-secondary);
}

.xs-text-block-intermediate-secondary-imp {
    display: block !important;
    font-size: var(--xs-font-size-intermediate) !important;
    color: var(--xs-color-secondary) !important;
}

.xs-text-block-intermediate-secondary-500 {
    display: block;
    font-size: var(--xs-font-size-intermediate);
    color: var(--xs-color-secondary);
    font-weight: 500;
}

.xs-text-block-intermediate-secondary-500-imp {
    display: block !important;
    font-size: var(--xs-font-size-intermediate) !important;
    color: var(--xs-color-secondary) !important;
    font-weight: 500 !important;
}

// -------------------------------------------------------------------------------------------------------

.xs-text-block-secondary {
    display: block;
    color: var(--xs-color-secondary);
}

.xs-text-block-secondary-500 {
    display: block;
    color: var(--xs-color-secondary);
    font-weight: 500;
}

// -------------------------------------------------------------------------------------------------------

.xs-text-small-secondary {
    font-size: var(--xs-font-size-small);
    color: var(--xs-color-secondary);
}

.xs-text-small-secondary-imp {
    font-size: var(--xs-font-size-small) !important;
    color: var(--xs-color-secondary) !important;
}

.xs-text-block-small {
    display: block;
    font-size: var(--xs-font-size-small);
}

.xs-text-block-small-500 {
    display: block;
    font-size: var(--xs-font-size-small);
    font-weight: 500;
}

.xs-text-block-small-secondary {
    display: block;
    font-size: var(--xs-font-size-small);
    color: var(--xs-color-secondary);
}

.xs-text-block-small-secondary-imp {
    display: block !important;
    font-size: var(--xs-font-size-small) !important;
    color: var(--xs-color-secondary) !important;
}

.xs-text-block-small-secondary-500 {
    display: block;
    font-size: var(--xs-font-size-small);
    color: var(--xs-color-secondary);
    font-weight: 500;
}

.xs-text-block-small-secondary-500-imp {
    display: block !important;
    font-size: var(--xs-font-size-small) !important;
    color: var(--xs-color-secondary) !important;
    font-weight: 500 !important;
}

// -------------------------------------------------------------------------------------------------------

.xs-text-block-extra-small {
    display: block;
    font-size: var(--xs-font-size-extra-small);
}

.xs-text-block-extra-small-secondary {
    display: block;
    font-size: var(--xs-font-size-extra-small);
    color: var(--xs-color-secondary);
}

.xs-text-block-extra-small-secondary-imp {
    display: block !important;
    font-size: var(--xs-font-size-extra-small) !important;
    color: var(--xs-color-secondary) !important;
}

.xs-text-block-extra-small-secondary-500 {
    display: block;
    font-size: var(--xs-font-size-extra-small);
    color: var(--xs-color-secondary);
    font-weight: 500;
}

.xs-text-block-extra-small-secondary-500-imp {
    display: block !important;
    font-size: var(--xs-font-size-extra-small) !important;
    color: var(--xs-color-secondary) !important;
    font-weight: 500 !important;
}

/*
-------------------------------------------------------------------------------------------------------
=== Brand ===
-------------------------------------------------------------------------------------------------------
*/

.lce-brand {
    background: #879fab;
    color: white;
    border-radius: 8px;
    font-family: monospace;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
}

/*
-------------------------------------------------------------------------------------------------------
=== Terminals ===
-------------------------------------------------------------------------------------------------------
*/

.lce-terminals-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-terminals-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Facility Town Stamp ===
-------------------------------------------------------------------------------------------------------
*/
.lce-facility-town-hall-stamps-load {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-facility-town-hall-stamps-load {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

.lce-facility-town-hall-stamp-token-historic-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-facility-town-hall-stamp-token-historic-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

.lce-facility-town-hall-stamp-token-create {
    .lce-facility-town-hall-stamp-token-phone-number {
        input {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
        }

        .p-inputgroup-addon {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
        }
    }

    .lce-facility-town-hall-stamp-token-number-of-stamps {
        font-size: 60px;
        height: 100%;
        width: 100%;
        text-align: center;
        color: var(--xs-color-secondary);
        border-radius: 8px;
        border: 1px solid var(--xs-color-discrete);
        width: 150px;
        height: 154px;
        margin-bottom: 15px;
        caret-color: transparent;

        &::placeholder {
            color: var(--xs-color-discrete);
            filter: brightness(95%);
        }
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Finances ===
-------------------------------------------------------------------------------------------------------
*/

.lce-finances-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-finances-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Dispatcher ===
-------------------------------------------------------------------------------------------------------
*/

.lce-dispatcher-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-dispatcher-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

.lce-delivery-company-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-delivery-company-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== User Management ===
-------------------------------------------------------------------------------------------------------
*/

.lce-user-management-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-user-management-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1750px;
        flex-grow: 1;
    }
}


/*
-------------------------------------------------------------------------------------------------------
=== Variables ===
-------------------------------------------------------------------------------------------------------
*/


/* --- User Status --- */
$onlineColor: rgb(89, 207, 17);
$offlineColor: gray;
$busyColor: var(--xs-color-danger);
$awayColor: orange;
$neverConnectedColor: black;

/*
-------------------------------------------------------------------------------------------------------
=== Settings ===
-------------------------------------------------------------------------------------------------------
*/

.lce-settings-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-settings-main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Birth Declarations ===
-------------------------------------------------------------------------------------------------------
*/

.lce-birth-declarations-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-birth-declarations-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Suggestions ===
-------------------------------------------------------------------------------------------------------
*/

.lce-suggestions-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-suggestions-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1750px;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Service Points ===
-------------------------------------------------------------------------------------------------------
*/

.lce-service-points-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-service-points-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Facilities ===
-------------------------------------------------------------------------------------------------------
*/

.lce-facilities-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-facilities-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Municipalities ===
-------------------------------------------------------------------------------------------------------
*/

.lce-municipalities-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-municipalities-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Districts ===
-------------------------------------------------------------------------------------------------------
*/

.lce-districts-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-districts-main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== User Management ===
-------------------------------------------------------------------------------------------------------
*/

.lce-user-management {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;

    .lce-user-management-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $pageMaxWidth;
        flex-grow: 1;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== User Status ===
-------------------------------------------------------------------------------------------------------
*/

/* === Background === */
.lce-user-status-background-online {
    background: $onlineColor;
}

.lce-user-status-background-offline {
    background: $offlineColor;
}

.lce-user-status-background-busy {
    background: $busyColor;
}

.lce-user-status-background-away {
    background: $awayColor;
}

.lce-user-status-background-neverConnected {
    background: $neverConnectedColor;
}

/* === Color === */
.lce-user-status-color-online {
    color: $onlineColor;
}

.lce-user-status-color-offline {
    color: $offlineColor;
}

.lce-user-status-color-busy {
    color: $busyColor;
}

.lce-user-status-color-away {
    color: $awayColor;
}

.lce-user-status-color-neverConnected {
    color: $neverConnectedColor;
}

/*
----------------------------------------------------------------------------------------------------
=== Stats ===
----------------------------------------------------------------------------------------------------
*/

.lce-statistic-alpha {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > div:first-child {
        background-color: var(--xs-color-discrete);
        filter: brightness(106%);
        width: 100%;
        min-width: 150px;
        max-width: 350px;
        min-height: 100px;
        border-radius: var(--xs-border-radius-default);
        border-left: 10px solid var(--xs-color-light);
    }
}

.lce-stats {
    background-color: var(--xs-color-discrete); //--lce-background-color-discrete
    filter: brightness(106%);
    min-width: 230px;
    max-width: 350px;
    min-height: 140px;
}

.lce-stats-description {
    font-size: var(--xs-font-size-small);
}

/*
----------------------------------------------------------------------------------------------------
=== Resource ===
----------------------------------------------------------------------------------------------------
*/

.lce-resource-partial-avatar-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 30px;
    border-radius: 6px;
    box-sizing: content-box;
    background: var(--xs-color-discrete);
    border: 1px solid var(--xs-color-discrete);
}

.lce-resource-partial-label {
    margin-bottom: 6px;
}

.lce-resource-partial-label-ml {
    margin-left: 47px;
}

/*
----------------------------------------------------------------------------------------------------
=== Articles ===
----------------------------------------------------------------------------------------------------
*/

.lce-article-partial-overlay-image-container {
    width: 100px !important;
    height: 100px !important;

    img {
        border-radius: 12px;
    }
}

.lce-article-partial-avatar {
    background: var(--xs-color-discrete);
    border: 1px solid var(--xs-color-discrete);

    &.lce-article-partial-avatar-icon-default {
        font-size: 25px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border-radius: 7px;
        box-sizing: content-box;
    }

    &.lce-article-partial-avatar-icon-medium {
        font-size: 35px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 62px;
        height: 62px;
        border-radius: 10px;
        box-sizing: content-box;
    }

    &.lce-article-partial-avatar-image-default {
        width: 42px !important;
        height: 42px !important;
        border-radius: 7px;

        img {
            border-radius: 7px;
        }
    }

    &.lce-article-partial-avatar-image-medium {
        width: 62px !important;
        height: 62px !important;
        border-radius: 10px;

        img {
            border-radius: 10px;
        }
    }
}

/*
----------------------------------------------------------------------------------------------------
=== Charts ===
----------------------------------------------------------------------------------------------------
*/

#certificateOrderChartLine {
    & > div {
        min-width: 800px;
    }
}

#certificateOrderChartDoughnut {
    & > div {
        max-width: 225px;
    }
}

.lce-chart {
    overflow: auto;

    canvas {
        height: 100% !important;
        width: 100% !important;
    }
}

/*
----------------------------------------------------------------------------------------------------
=== Background Icons ===
----------------------------------------------------------------------------------------------------
*/

.lce-background-icon-delivery-company::after {
    content: "\f0d1";
}

.lce-background-icon-suggestion::after {
    content: "\f0eb";
}

.lce-background-icon-customer::after {
    content: "\f508";
}

.lce-background-icon-service-point::after {
    content: "\f0b1";
}

.lce-background-icon-terminal::after {
    content: "\f0b1";
}

.lce-background-icon-facility::after {
    content: "\f1ad";
}

.lce-background-icon-municipality::after {
    content: "\f19c";
}

.lce-background-icon-district::after {
    content: "\f279";
}

.lce-background-icon-users::after {
    content: "\f0c0";
}

.lce-background-icon-settings::after {
    content: "\f085";
}

.lce-background-icon-parameters::after {
    content: "\f1de";
}

/*
----------------------------------------------------------------------------------------------------
=== Customer Icons ===
----------------------------------------------------------------------------------------------------
*/

.lce-certificate-icon-outline {
    display: block;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url('/assets/icons/lce-certificate-outline.svg');
    mask-image: url('/assets/icons/lce-certificate-outline.svg');
    background-color: currentColor;
    width: 6px;
    height: 6px;
}

/*
-------------------------------------------------------------------------------------------------------
=== Dropdown Compose ===
-------------------------------------------------------------------------------------------------------
*/

.lce-input-dropdown-compose {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 3px;

    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;

    .p-dropdown-label {
        color: var(--xs-color-secondary) !important;
        font-size: var(--xs-font-size-intermediate) !important;
        font-weight: 500;
    }

    .p-inputtext {
        padding-left: 0 !important;
    }

    &.lce-input-dropdown-compose-border {
        border: 1px solid var(--xs-color-light);
    }

    &.lce-input-dropdown-compose-stripe {
        border-left: 3px solid var(--xs-color-secondary);
    }

    i {
        margin-right: 8px;
    }
}

/*
-------------------------------------------------------------------------------------------------------
=== Import ===
-------------------------------------------------------------------------------------------------------
*/

@import './lce-shared-news';
@import './lce-shared-smartcity';
@import './lce-certificate-order-process';
